
import {
  Component,
  Vue,
} from 'vue-property-decorator';

// Lazy loading imports
const UsernamePassword = () => import(
  /* webpackChunkName: "login-username-password" */
  /* webpackPrefetch: false */
  '@/components/entry/reset/UsernamePassword.vue'
);

type Type = 'UsernamePassword' | 'WebAuthn'

@Component({
  name: 'Reset',
  components: {
    UsernamePassword,
  },
})
export default class Reset extends Vue {
  private match = (): Type => {
    const type = 'password';
    switch (type) {
      case 'password':
        return 'UsernamePassword';
      default:
        throw Error(`No component available for ${type}`);
    }
  };
}
